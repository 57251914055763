<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="资产类型" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
              <a-select
                @change="handleCategoryChange"
                v-decorator="[
                  'category',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ]
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option v-for="(val, key) in this.$Dictionaries.property_project_category" :value="key" :key="key">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="内容" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
              <a-input
                v-decorator="['content', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="风险" v-if="this.category ===1" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
              <a-switch v-decorator="['is_risk', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="名称" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
              <a-input
                v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="销售类型" v-if="this.category ===1" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
              <a-input
                v-decorator="['seal_type', {rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="法律" v-if="this.category ===1" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
              <a-switch v-decorator="['is_law', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="this.category ===1">
            <a-form-item label="附件">
              <a-table bordered :data-source="attachment" :columns="attachment_columns">
                <template slot="name" slot-scope="text, record">
                  <editable-cell :text="text" @change="onCellChange(record.key, 'name', $event)" />
                </template>
                <template slot="count" slot-scope="text, record">
                  <editable-cell :text="text" @change="onCellChange(record.key, 'count', $event)" />
                </template>
                <template slot="f" slot-scope="f, record">
                  <a-upload
                    v-decorator="['f']"
                    name="file"
                    :action="actionUrl"
                    list-type="picture-card"
                    :data="new_multipart_params"
                    :before-upload="beforeUpload"
                    @change="handleUploadChange(record)"
                  >
                    <div v-if="record.f.length === 2">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        文件上传
                      </div>
                    </div>
                  </a-upload>
                </template>
                <template slot="action" slot-scope="text, record">
                  <a href="javascript:;" @click="addItem">添加</a>
                  <a-divider type="vertical" />
                  <a-popconfirm
                    v-if="attachment.length"
                    title="确定删除吗?"
                    @confirm="() => onDeleteItem(record.key)"
                  >
                    <a href="javascript:;">删除</a>
                  </a-popconfirm>
                </template>
              </a-table>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import { oss_token_list } from '@/api/oss_token'

const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-input :value="value" @change="handleChange" @pressEnter="check" /><a-icon
            type="check"
            class="editable-cell-icon-check"
            @click="check"
          />
        </div>
        <div v-else class="editable-cell-text-wrapper">
          {{ value || ' ' }}
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
    `,
  props: {
    text: String
  },
  data () {
    return {
      value: this.text,
      editable: false
    }
  },
  methods: {
    handleChange (e) {
      const value = e.target.value
      this.value = value
    },
    check () {
      this.editable = false
      this.$emit('change', this.value)
    },
    edit () {
      this.editable = true
    }
  }
}
export default {
  components: {
    EditableCell
  },
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      category: -1,
      count: 1,
      actionUrl: '',
      fileList: [],
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      attachment: [
        {
          key: '0',
          name: '名称',
          count: '数量',
          f: '文件'
        }
      ],
      attachment_columns: [
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '数量',
          dataIndex: 'count',
          ellipsis: true,
          scopedSlots: { customRender: 'count' }
        },
        {
          width: 180,
          title: '文件',
          dataIndex: 'f',
          align: 'center',
          scopedSlots: { customRender: 'f' }
        },
        {
          width: 130,
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  beforeCreate () {
    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
  },
  methods: {
    handleCategoryChange (value) {
      this.category = Number(value)
      console.log(value)
    },
    onCellChange (key, dataIndex, value) {
      const dataSource = [...this.attachment]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.attachment = dataSource
      }
    },
    beforeUpload (file) {
      this.new_multipart_params.key = file.uid + '_' + file.name
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        this.$message.error('文件过大，请重新上传')
      }
      console.log('--------------------------------------', isLt10M)
      return isLt10M
    },
    handleUploadChange (record) {
      console.log(record)
      const dataSource = [...this.attachment]
      const target = dataSource.find(item => item.key === record.key)
      if (target) {
        target['f'] = this.new_multipart_params.key
        this.attachment = dataSource
      }
    },
    addItem () {
      const { count, attachment } = this
      const newData = {
        key: count,
        name: `名称`,
        count: '数量',
        f: `文件`
      }
      this.attachment = [...attachment, newData]
      this.count = count + 1
    },
    onDeleteItem (key) {
      const dataSource = [...this.attachment]
      this.attachment = dataSource.filter(item => item.key !== key)
    }
  }
}
</script>
<style scoped>

.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
