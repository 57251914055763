<template>
  <child-icon :style="{ fontSize: '16px' }"/>
</template>
<script>
const ChildSvg = {
  template: `
  <svg t="1683535535164" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10124" width="16" height="16"><path d="M95.887 66c16.27 0 29.503 13.01 29.878 29.2l0.008 0.707v778.28c0 11.045 8.955 20 20 20h782.34c16.27 0 29.504 13.01 29.887 29.2v0.706c0 16.281-13.001 29.524-29.181 29.907H118c-28.719 0-52-23.281-52-52V95.907C66 79.39 79.38 66 95.887 66z" fill="#bfbfbf" p-id="10125"></path></svg>
  `
}

const ChildIcon = {
  template: `<a-icon :component="ChildSvg" />`,
  data () {
    return {
      ChildSvg
    }
  }
}

export default {
  components: {
    ChildIcon
  }
}
</script>
<style scoped>
</style>
